import 'select2'
import '../backend/codebase/app.js'
import 'flickity/dist/flickity.pkgd.min'
import 'inputmask'
import 'inputmask/dist/bindings/inputmask.binding.js'
import tippy, {followCursor} from "tippy.js"

jQuery($ =>
{
    // =====================================================
    // Map (with tippy.js)
    // =====================================================

    tippy('.map-location', {
        content(el)
        {
            return $(el).attr('data-content')
        },
        followCursor: true,
        placement: 'right-start',
        offset: [0, 40],
        plugins: [followCursor],
        allowHTML: true,
    });

    // Clicking
    $('.map-location').not('.over').click(function()
    {
        window.open_tab($(this).attr('data-target'), true);
    });

    window.open_tab = function(event_id, animation = false)
    {
        $('.venue .nav-item').removeClass('tab_active');
        $('.venue .nav-link').removeClass('active');
        $('.venue .tab-pane').removeClass('active show');

        $('.venue#'+event_id+' .registration-tab').addClass('tab_active');
        $('.venue#'+event_id+' .registration-tab .nav-link').addClass('active');
        $('.venue#'+event_id+' .tab-pane.registration-content').addClass('active show');

        if(animation)
        {
            $('html,body').animate({scrollTop: $('.venue#'+event_id).offset().top},'slow');
        }
    }


    // =====================================================
    // Events Carousel
    // =====================================================

    $('.events-slider').flickity({
        contain: true,
        pageDots: false,
        autoPlay: true,
    });


    // =====================================================
    // Select2 Dropdowns
    // =====================================================

    $('.js-select2').select2()


    // =====================================================
    // Masked Phone Inputs
    // =====================================================

    //jQuery('.js-masked-phone').mask('999 999 99 99')


    // =====================================================
    // Venue nav tabs - Fix (close & open)
    // =====================================================

    let $venue_tab_active = $('.venue .nav-tabs .nav-item')

    $venue_tab_active.click(e =>
    {
        let $this = $(e.currentTarget)
        let tab_active = 'tab_active'

        $this.siblings().removeClass(tab_active)

        if(!$this.toggleClass(tab_active).hasClass(tab_active))
        {
            $this.find('.nav-link').toggleClass('active')
            $this.closest('.venue').find('.tab-content .tab-pane.active').toggleClass('active show')
        }
    })



    // =====================================================
    // Car & Public Transport - Search directions
    // =====================================================

    $('.departure_point_btn').click(e =>
        open_directions(e)
    )

    $('.departure_point').keyup(e =>
    {
        if(e.keyCode == 13)
        {
            open_directions(e)
        }
    })

    function open_directions(e)
    {
        let $this = $(e.currentTarget)
        let $input = $this.closest('.directions_search').find('input')

        if($input.val())
        {
            let url = $input.data('url').replace('{departure_point}', $input.val());

            let win = window.open(url, '_blank')

            if(win)
            {
                win.focus()
            }
            else
            {
                alert('Bitte erlauben Sie Pop-ups für diese Webseite in Ihren Browser-Einstellungen.')
            }
        }
    }


    // =====================================================
    // Menu toggle
    // =====================================================

    $('.menu-toggle').click(e => {
        $(e.currentTarget).toggleClass('is-active')
    })


    // =====================================================
    // Registration form - Change event
    // =====================================================

    $('.venues-container form').change(e => {
        let $this_form = $(e.currentTarget)

        // Hide the ".non-abstracts" containers if the user selects "Abstracts"
        if($this_form.find('.registration_type_container input:checked').val() == 3)
        {
            $this_form.find('.non-abstracts').addClass('hidden')
        }
        else
        {
            $this_form.find('.non-abstracts').removeClass('hidden')
        }


        // Control the workshop selection
        let $radio_checked = $this_form.find('.price-container input:checked')

        let $radio_workshop1 = $this_form.find('.workshops input[value="1"]')
        let $radio_workshop2 = $this_form.find('.workshops input[value="2"]')
        let $radio_workshop3 = $this_form.find('.workshops input[value="3"]')

        if (
            $radio_checked.val() == 'earlybird_onsite' ||
            $radio_checked.val() == 'normalrate_onsite'
        )
        {
            $radio_workshop1.attr('disabled', false).prop('checked', true).closest('.css-control').removeClass('disabled')
            $radio_workshop2.attr('disabled', true).prop('checked', false).closest('.css-control').addClass('disabled')
            $radio_workshop3.attr('disabled', true).prop('checked', false).closest('.css-control').addClass('disabled')
        }
        else if (
            $radio_checked.val() == 'earlybird_livestream' ||
            $radio_checked.val() == 'normalrate_livestream'
        )
        {
            $radio_workshop1.attr('disabled', true).prop('checked', false).closest('.css-control').addClass('disabled')
            $radio_workshop2.attr('disabled', false).closest('.css-control').removeClass('disabled')
            $radio_workshop3.attr('disabled', false).closest('.css-control').removeClass('disabled')
        }
    })

    $('.venues-container form').trigger('change')


    // =====================================================
    // If GET parameter "registration" is set, then scroll to the corresponding registration form
    // =====================================================

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if(urlParams.get('registration'))
    {
        $(window).scrollTop($('#' + urlParams.get('registration')).offset().top);
    }
})
